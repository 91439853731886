<template>
  <div class="demo-register">
    <h1 class="text-light">{{ $t('auth.demo-register.form.register') }}</h1>
    <form method="post" v-on:submit.prevent="demoRegister" name="demo-register">
      <div class="with-icon-left form-group">
        <div class="input-group" :class="error.password ? 'has-error' : ''">
          <input
            :class="{ valid: passwordValidation.valid }"
            :type="passwordVisible ? 'text' : 'password'"
            v-model="password"
            required
          />
          <i class="icon-left fa fa-unlock-alt input-icon"></i>
          <label class="control-label" for="email">{{
            $t('auth.password')
          }}</label>
          <i class="bar"></i>
          <button
            class="icon-right visibility"
            tabindex="-1"
            @click.prevent="togglePasswordVisibility"
            :arial-label="passwordVisible ? 'Hide password' : 'Show password'"
          >
            <i :class="passwordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </button>
          <span class="text-danger small">{{ error.password }}</span>
        </div>
      </div>
      <div class="with-icon-left form-group">
        <div class="input-group" :class="error ? 'has-error' : ''">
          <input type="password" v-model.lazy="checkPassword" required />
          <i class="icon-left fa fa-unlock-alt input-icon"></i>
          <label class="control-label" for="email">{{
            $t('auth.confirm-password')
          }}</label>
          <i class="bar"></i>
          <span class="text-danger small">{{
            error.password_confirmation
          }}</span>
        </div>
      </div>

      <transition name="hint" appear>
        <div
          v-if="passwordValidation.errors.length > 0 && !submitted"
          class="hints"
        >
          <h2>Hints</h2>
          <p v-for="error in passwordValidation.errors" :key="error">
            {{ error }}
          </p>
        </div>
      </transition>

      <span v-if="notSamePasswords" class="text-danger small"
        >Passwords don't match.</span
      >
      <p></p>

      <div class="d-flex justify--center align--center">
        <half-circle-spinner
          slot="loading"
          :animation-duration="1500"
          :size="25"
          color="#74b9ff"
          v-if="formLoading"
          class="mx-3"
        />
        <button
          :disabled="
            notSamePasswords ||
              passwordValidation.errors.length > 0 ||
              !passwordsFilled
          "
          v-else
          type="submit"
          class="btn-block btn-with-icon-sm btn btn-primary btn-sm"
        >
          <div class="btn-with-icon-content">
            {{ $t('auth.demo-register.form.register') }}
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { api } from '../../../axios'
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import { mapGetters } from 'vuex'
export default {
  name: 'forget-password',
  data() {
    return {
      formLoading: false,
      email: '',
      error: {},
      password: '',
      checkPassword: '',
      passwordVisible: false,
      submitted: false
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
    notSamePasswords() {
      if (this.passwordsFilled) {
        return this.password !== this.checkPassword
      } else {
        return false
      }
    },
    passwordsFilled() {
      return this.password !== '' && this.checkPassword !== ''
    },
    passwordValidation() {
      let errors = []
      for (let condition of this.passwordRules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message)
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors }
      } else {
        return { valid: false, errors }
      }
    },
    passwordRules() {
      return [
        {
          message: this.$t(
            'auth.demo-register.form.password-rules.one-lowercase'
          ),
          regex: /[a-z]+/
        },
        {
          message: this.$t(
            'auth.demo-register.form.password-rules.one-uppercase'
          ),
          regex: /[A-Z]+/
        },
        {
          message: this.$t(
            'auth.demo-register.form.password-rules.eight-characters'
          ),
          regex: /.{8,}/
        },
        {
          message: this.$t('auth.demo-register.form.password-rules.one-number'),
          regex: /[0-9]+/
        },
        {
          message: this.$t(
            'auth.demo-register.form.password-rules.special-character'
          ),
          regex: /^(?=.*[!-\/:-@[-`{-~])/
        }
      ]
    }
  },
  components: {
    HalfCircleSpinner
  },
  methods: {
    resetPasswords() {
      this.password = ''
      this.checkPassword = ''
      this.submitted = true
      setTimeout(() => {
        this.submitted = false
      }, 2000)
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible
    },
    async demoRegister() {
      this.formLoading = true
      try {
        this.error = ''
        const response = await api.post(
          `v1/demo-requests/verify/${this.$route.query.token}`,
          {
            password: this.password
          }
        )
        this.toast(this.$t('auth.demo-register.form.registered'))

        this.$router.push({ name: 'login' })
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
      this.formLoading = false
    }
  }
}
</script>

<style lang="scss">
$bgColor: #3a81c8;
$color: #d4dedf;
.reset-password {
  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    .down-container {
      display: none;
    }
  }

  h1 {
    text-align: center;
    margin-top: 0;
    font-weight: bold;
    font-size: 30px;
  }
  width: 21.375rem;

  .down-container {
    margin-top: 3.125rem;
  }
}

.hints {
  padding: 1em;
  background: linear-gradient(to bottom right, #008ecf, #0d4d80);
  margin: 1em 0;
  font-size: 0.9em;
  color: #ffffff;
  border-radius: 10px;
  h2 {
    font-size: 20px;
    margin: 2px 0 10px 0;
  }
  p {
    margin: 0;
    padding-left: 1em;
    &::before {
      content: '>';
      font-size: 0.9em;
      margin-right: 6px;
      display: inline-block;
    }
  }
}

.visibility {
  all: unset;
  top: 0;
  position: absolute;
  right: 0;
  background: transparent;
  display: inline-block;
  color: $bgColor;
  padding: 2px 0.4em 0;
  vertical-align: center;
}

.material-icons {
  font-size: 1.5em;
}
.password {
  display: flex;
  align-items: center;
}

.hint {
  &-enter {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  &-leave-to {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  &-enter-active {
    transition: 300ms;
  }
  &-leave-active {
    transition: 400ms;
  }
}
</style>
